import * as React from 'react'
import Hero from '../components/Hero'
import Seo from '../components/SEO'
import TrustedCompany from '../components/TrustedCompany'
import till from '../images/people/till-carlos.webp'
import feature1 from '../images/uploads/feature1.webp'
import feature2 from '../images/uploads/feature2.webp'
import feature3 from '../images/uploads/feature3.webp'
import benefit1 from '../images/uploads/benefit-1.webp'
import benefit2 from '../images/uploads/benefit-2.webp'
import benefit3 from '../images/uploads/benefit-3.webp'
import bg_hero from '../images/uploads/bg-about.svg'
// import patch_icon from '../images/icons/patch-check-fll.png'
// import battery_icon from '../images/icons/battery-charging.png'
import people_icon from '../images/icons/people.png'
// import shift_icon from '../images/icons/shift-fill.png'
// import card_checklist_icon from '../images/icons/card-checklist.png'
import icon_patch_check_fll from '../images/icons/patch-check-fll.png'
import icon_shield from '../images/icons/shield-fill-check.svg'
import icon_building from '../images/icons/building.svg'
import icon_joystick from '../images/icons/joystick.svg'
import icon_diamond from '../images/icons/diamond.svg'
import check from '../images/icons/check.svg'
import marketingPhoto from '../images/uploads/marketing-photo.webp'
import developersPhoto from '../images/uploads/developers-photo.webp'

import questions from '../images/icons/questions.svg'
import Link from '../components/Link'
import YoutubeEmbed from '../components/YoutubeEmbed'

// technologies
import vue from '../images/logos/vue.png'
import ruby from '../images/logos/ruby.svg'
import rails from '../images/logos/rails.svg'
import graphql_icon from '../images/logos/graphql.svg'

// casestudies
import casestudy_1 from '../images/casestudies/casestudy_1.png'
import casestudy_2 from '../images/casestudies/casestudy_2.png'
import casestudy_3 from '../images/casestudies/casestudy_3.png'
import casestudy_4 from '../images/casestudies/casestudy_4.png'
import casestudy_5 from '../images/casestudies/casestudy_5.png'
import casestudy_6 from '../images/casestudies/casestudy_6.png'
import team from '../images/uploads/team-photo.webp'

const feadbacks = [
  {
    heading: '“Why buy from you? What makes you so special?”',
    content:
      'True, you can get developers from anywhere. There are countless of local agencies and upwork is full of developers who can “work per requirement, sir”. Pairing is a system with people behind it, that delivers results. We beat the prices of local agencies (by far) while not having the uncertainty of hiring random developers overseas. ',
  },
  {
    heading: '“What exactly am I signing up for?”',
    content:
      "In the first step it's just a free call where we talk about your needs and what it would take to build your project. Nothing to lose here but 15 minutes of your time. Hiring developers after that is a 1-3 months commitment",
  },
  {
    heading: '“What happens if I wait?”',
    content:
      'Your idea or feature will not get built. Nothing wrong with this, the market will decide if they still want your product. ',
  },
  {
    heading: '“Are there any long-term contracts?”',
    content: 'Only if you want a long-term discount.',
  },
  {
    heading: '“What languages do your developers speak?”',
    content:
      'Everyone is speaks fluent English. Some speak German. And of course local languages outside of our main market.',
  },
  {
    heading: '“Who are you guys anyways?”',
    content:
      'We are a team that worked together for years. The founder himself is a senior developer who built complex systems. We understand the client requirement as well as what it takes to attract great talent.',
  },
  {
    heading: '“What results will I actually get from this?”',
    content:
      "Depending on time, budget and scope, you will get the best product you specified. But don't be scared: there is a Pair of smart devs helping you on the way.",
  },
  {
    heading: "“What's included in the support service?”",
    content:
      "You have access to the oversight dev every day. The main dev is working for you full-time and you know exactly when and what they work on. We also give you some tips on when and how you can interrupt them.Additionally there's support for product + marketing from our founder on a case-by-case basis.",
  },
  {
    heading: '“How much does it cost? Are there any hidden fees?”',
    content:
      'No hidden fees, no payment fees. We charge per pair day and only if the full day was worked.',
  },
  {
    heading: '“What tools does it integrate with?”',
    content:
      'We use trello, clickup, and gitlab internally. We also integrate with your PM software if you have preferences.',
  },
  {
    heading: '“Where are your developers?”',
    content:
      "Asia, Europe, and some in Africa. We don't discriminate for anything. However, we make sure that your developer is in a time zone where you can reach them easily.",
  },
]

const casestudies = [
  {
    key: 1,
    title: 'Startup (SaaS for Chatbots)',
    name: 'Dan, self-funded',
    company: 'Chat Backend SaaS',
    detail_image: casestudy_1,
    icon: icon_joystick,
    description:
      '“Before we hired a senior engineer from Pairing, we were in a with our agency that built our chat-backend software” “After getting this senior engineer, we were able to uncover who was not performing and replace them with much better ones."',
  },
  {
    key: 2,
    title: 'Medical Workflow system - launched bug free',
    name: 'Mathias, Project Manager',
    company: '[undisclosed] client is a German city',
    detail_image: casestudy_2,
    icon: icon_patch_check_fll,
    description:
      '“It really worked from day 1. The first week was a bit slow, but in the second we were running up to 8 thousand patients - every day. The system help up really well against the traffic.”',
  },
  {
    key: 3,
    title: 'Developer leasing',
    name: 'Dominic and Volker, Project Managers',
    company: '[undisclosed] client is a marketing agency',
    detail_image: casestudy_3,
    icon: icon_building,
    description:
      '“The developers from Pairing work just as our employees. They prove to be an important part of our teams. Whatever we assign them gets done.”',
  },
  {
    key: 4,
    title: 'Small business invented a product',
    name: 'Silas Fuchs (Product Owner)',
    company: 'Northdocks GmbH',
    detail_image: casestudy_4,
    icon: icon_diamond,
    description:
      '“We had no idea how to make a complex software like FirefighterVR. We thought about hiring a person in Germany, but were scared of the fix cost.” “Pairing gave us a clear estimation of the MVP, and then continued to provide fixes. We were able to launch successfully and even resell our platform as a white label product.”',
  },
  {
    key: 5,
    title: 'Patient Access Pass - Hacker proof',
    name: 'Lasse, Project Manager',
    company: '[undisclosed] Government agency',
    detail_image: casestudy_5,
    icon: icon_shield,
    description:
      '“The system help up really well against traffic. We absolutely needed to make sure no hacker could access patient data. Pairing developed a structure for us that was basically read-only and could not get hacked.”',
  },
  {
    key: 6,
    title: 'Full team provisioned',
    name: 'Fabian, Product Owner',
    company: '[undisclosed] client company that needed workforce',
    detail_image: casestudy_6,
    icon: people_icon,
    description:
      '“The team worked really well together. I gave them tickets, which got done. Their management and invoicing has been absolutely transparent. In the end we were able to develop significant features with Pairing.”',
  },
]

const technologies = [
  {
    name: 'Vue',
    icon: vue,
  },
  {
    name: 'Ruby',
    icon: ruby,
  },
  {
    name: 'Rails',
    icon: rails,
  },
  {
    name: 'GraphQL',
    icon: graphql_icon,
  },
]
const IndexPage = () => {
  const [activeKey, setActive] = React.useState(1)
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setActive(parseInt(activeKey) + 1)
      if (activeKey > casestudies.length - 1) setActive(1)
    }, 10000)
    return () => {
      clearInterval(intervalId)
    }
  })
  return (
    <>
      <Seo title="Home" />
      <Hero className="text-center md:pt-8">
        <div className="max-w-7xl">
          <div className="max-w-5xl mx-auto mb-6">
            <h1 className="typo-h2 mb-4 md:leading-[72px]  px-4 text-3xl md:text-6xl ">
              We are the
              <span className="underline-highlight"> Technical </span> Team
              Behind your startup (incl. CTO)
            </h1>
          </div>

          <img
            src={team}
            alt="Team"
            srcSet=""
            className="w-full xl:min-h-[429px]"
          />

          <p className="typo-normal max-w-5xl text-center mx-auto lg:pl-20 pt-12">
            Our developers logged over 6800 developer hours and hit dozens of
            deadlines. Learn more in our{' '}
            <Link to="/casestudies" className="text-blue-700">
              case studies
            </Link>
          </p>

          {/* <hr  /> */}
          <div className="flex flex-col items-center my-6">
            <Link
              className="mt-8 w-[20rem] font-bold"
              variant="primary"
              to="/book"
              type="btn"
              size="extra"
            >
              Get a Price
            </Link>
            <div className="flex justify-between mt-2 text-gray-800 ">
              <span className="text-xs md:text-sm mr-4 flex items-center">
                <img
                  src={check}
                  alt="Icon Check"
                  className="w-4 h-4 hidden md:block mr-1 dark:bg-teal-neutral "
                />
                15 min with an engineer
              </span>
              <span className="text-xs md:text-sm flex items-center">
                <img
                  src={check}
                  alt="Check Icon"
                  className="w-4 h-4 hidden md:block mr-1 dark:bg-teal-neutral"
                />
                No sales pitch
              </span>
            </div>
          </div>
          <p className="typo-normal max-w-3xl mx-auto px-2">
            We give you a free assessment of your requirements. Our developers
            work in pairs, with built-in quality checks.
          </p>
        </div>
      </Hero>
      <section className="main-section">
        <h2 className="typo-h2 text-center">Trusted by Great Companies</h2>
        <TrustedCompany />
      </section>
      <section className="main-section max-w-6xl mx-auto">
        <h2 className="typo-h2 text-center">
          The results our clients got from 6800+ logged hours through Pairing
        </h2>
        <div className="mt-8">
          <YoutubeEmbed embedId="gYuCKmM2yNE" />
        </div>
      </section>
      <section className="main-section">
        <h2 className="typo-h1 underline-highlight">Case Studies</h2>
        <p className="text-center">
          We worked with startups, marketing agencies, insurance, medical. See{' '}
          <Link to="/casestudies" className="text-blue-700">
            all case studies
          </Link>
          .
        </p>
        <div className="row-section mx-0 mt-6">
          <div className="flex flex-col max-w-2xl ml-0 md:ml-4">
            {casestudies.map((item) => (
              <div
                key={item.key}
                onClick={(e) => setActive(item.key)}
                className={`py-2 px-4 w-full md:w-3/4 my-2  flex flex-col  rounded-sm  text-gray-800 dark:text-gray-100 shadow-xl border-2 border-primary overflow-y-hidden ${
                  activeKey === item.key
                    ? 'bg-blue-50 dark:bg-dark_bg-lighter relative'
                    : 'relative '
                }`}
              >
                <div className="flex items-center text-intro cursor-pointer">
                  <img
                    src={item.icon}
                    className="w-6 h-6 p-1 dark:bg-teal-neutral"
                    alt="Icon"
                    srcset=""
                  />
                  <span className="font-bold mx-2 dark:text-white">
                    {item.title}
                  </span>
                </div>
                {activeKey === item.key && (
                  <div className="ml-8 md:h-72">
                    <div className="py-1 leading-normal text-gray-700 dark:text-white">
                      <blockquote className="my-4">
                        {item.description}
                      </blockquote>
                      <h5 className="font-bold">{item.name}</h5>
                      <span className="font-light text-base">
                        {item.company}
                      </span>
                    </div>
                    <div className="slideranimate absolute overflow-y-hidden  top-0 left-0 w-2 h-16 md:h-20  bg-blue-400"></div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="my-4 w-72">
            <img
              className="w-64 h-64 rounded-full border-8 border-dark_bg-light shadow-md"
              src={
                casestudies.find((item) => item.key === activeKey)?.detail_image
              }
              alt="Vision"
            />
          </div>
        </div>
      </section>

      <section className="main-section">
        <h2 className="typo-h1 text-center max-w-6xl underline-highlight">
          Benefits
        </h2>
        <p className="text-center">
          Our clients experience these changes in how they work with software
          developers
        </p>
        <div className="flex md:flex-row flex-col justify-between w-full max-w-7xl mx-auto">
          <div className="w-full flex flex-col items-center justify-end">
            <img src={benefit1} alt="Estimation" className="w-3/4 my-4" />
            <p className="uppercase text-primary font-bold text-center text-2xl max-w-xs">
              Trust Estimations again
            </p>
          </div>
          <div className="w-full flex flex-col items-center justify-end">
            <img src={benefit2} alt="Shipment" className="w-3/4 my-4" />
            <p className="uppercase text-primary font-bold text-2xl text-center max-w-xs">
              Feel better about what you ship
            </p>
          </div>

          <div className="w-full flex flex-col items-center justify-end">
            <img src={benefit3} alt="Atm Machine" className="w-3/4 my-4" />
            <p className="uppercase text-primary font-bold text-2xl text-center max-w-sm">
              Decrease the cost of expensive consultants
            </p>
          </div>
        </div>
      </section>
      <section className="main-section">
        <h2 className="typo-h1 underline-highlight">Features</h2>
        <p className="text-center">
          You will receive these things along the way to your software success
        </p>
        <div className="row-section items-start justify-between my-8 px-4">
          <img
            className="w-[22rem] sm:w-96 h-[15rem] sm:h-64 max-w-sm object-cover"
            width="320"
            src={feature1}
            alt="Feature 1"
          />

          <div className="max-w-lg mt-6 md:mt-2 md:ml-24">
            <h2 className="typo-h3">A clear action plan</h2>
            <p>
              You get a clear action plan and estimation after the first month
              working with TechLead. + Weekly updates.
            </p>
          </div>
        </div>
        <div className="row-section items-start justify-between my-6 md:my-10">
          <img
            className="w-[22rem] sm:w-96 h-[15rem] sm:h-64 max-w-sm object-cover"
            src={feature2}
            alt="Feature 2"
            width="320"
          />

          <div className="max-w-lg md:ml-24 mt-6 md:mt-4">
            <h2 className="typo-h3">Developers show progress</h2>
            <p>
              Developers that will work on your product and will give you the
              daily check-ins, and the process on the action plan we have set in
              place.
            </p>
            This will help you clearly see these
            <ul className="mx-8 list-disc">
              <li>feature that get done (progress towards the end goal)</li>
              <li>visibility of exactly what has been completed</li>
              <li>reduce uncertainty of technical challenges.</li>
            </ul>
          </div>
        </div>
        <div className="row-section items-start justify-between my-8">
          <img
            className="w-[22rem] sm:w-96 h-[15rem] sm:h-64 max-w-sm object-cover"
            width="320"
            src={feature3}
            alt="Feature 3"
          />

          <div className="max-w-lg md:ml-24 mt-6 md:mt-2">
            <h2 className="typo-h3">Get a high quality, working software</h2>
            <p>
              Every time you hire a new developer you need an extensive
              onboarding process. Every company needs that. But be honest: is
              yours working optimally?
            </p>

            <p>
              We built onboarding into the trial teams. That means you don't
              even pay for it. We teach new developers best practices and make
              sure they hit the ground running when they join your team.
            </p>
          </div>
        </div>
      </section>

      <section className="main-section">
        <h1 className="typo-h2 font-semibold max-w-4xl text-center -mt-8 md:mt-0 md:py-10 ">
          <span className="underline-highlight">Just one Pairing team </span>
          improved our bottom line by 6%. This sounds small but it's actually a
          big deal!”
        </h1>
      </section>

      <section className="flex flex-col w-full px-6 sm:px-16 md:px-32 mx-auto py-16 bg-gray_faq_block ">
        <h2 className="typo-h1">Pricing</h2>
        <p>Depending on your scope and stage of your project</p>
        <div className="flex flex-col items-center justify-center">
          <ul className="w-full">
            <li>✅ Price only depends on how much work you want to get done</li>
            <li>
              ✅ Unlimited access to the daily dashboard, also for your team
            </li>
            <li>
              ✅ Risk-free guarantee: our committed deadlines will be met, at no
              extra charge for you in case we miss them.
            </li>
            <li>✅ Daily work dashboard</li>
            <li>
              ✅ Each developer is paired with a QA person that understands your
              business case
            </li>
          </ul>
        </div>
      </section>

      <section className="row-section">
        <div className="ml-2 md:ml-8 my-6 md:my-0">
          <img className="max-w-lg w-full" src={till} alt="Till Carlos" />
        </div>
        <div className="max-w-xl">
          <h1 className="typo-h2">Founded by a German in Estonia</h1>
          <p className="mb-4">
            Pairing.dev was founded in 2017 by Dipl.-Inf. Till Carlos. Coming
            from a freelance senior development position, Till grew the team
            organically without taking outside investment.
            <br /> <br />
            Now the team consists of 15 specialists in 10 countries. We value
            freedom, collaboration and industriousness.
          </p>
          <Link
            className="mt-8"
            variant="primary"
            to="/book"
            type="btn"
            size="extra"
          >
            Book a call with Till
          </Link>
        </div>
      </section>
      <section className="main-section negative-mb relative">
        <h1 className="typo-h2 text-center text-[1.4rem] md:text-4xl max-w-4xl my-4">
          Marketing and Management
        </h1>
        <div>
          <img
            className="md:mt-16 overflow-hidden w-full"
            src={marketingPhoto}
            alt="Marketing team"
          />
        </div>
        <div
          className="hidden md:block absolute w-full top-16 bg-gradient-to-b from-white dark:from-dark_bg-normal via-blue-50 dark:via-dark_bg-light to-white dark:to-dark_bg-normal"
          style={{ zIndex: '-1' }}
        >
          <img src={bg_hero} alt="Background" />
        </div>
      </section>
      <section className="main-section bg-gray-100 relative negative-mb pb-0 md:pt-16 ">
        <div>
          <h2 className="typo-h3 text-center">We develop Full Stack</h2>
          <p className="text-center text-lg">with high ROI Frameworks</p>
          <div className="flex items-center flex-wrap justify-center w-full p-8 md:w-full mb-4">
            {technologies.map((item, index) => (
              <span key={index} className="p-2 mx-4 md:mx-8 my-2 shadow-md">
                <img src={item.icon} className="w-12 h-12" alt={item.name} />
              </span>
            ))}
          </div>
        </div>
        <h1 className="typo-h2 text-center max-w-4xl my-4">Development Team</h1>
        <div>
          <img
            className="md:mt-16 overflow-hidden w-full"
            src={developersPhoto}
            alt="Engineer team"
          />
        </div>
      </section>

      <section className="bg-gray-200 negative-mb">
        <h2 className="typo-h2 text-center mt-16 py-4">FAQ</h2>
        <div className="flex flex-col items-center md:py-8 py-4 max-w-7xl mx-auto">
          <div className="flex flex-wrap justify-center">
            {feadbacks.map((fqa, index) => (
              <div key={index} className="w-full max-w-[32rem] m-4">
                <div className="flex items-center">
                  <img src={questions} alt="question faq icon" />
                  <p className="font-bold text-xl">{fqa.heading}</p>
                </div>
                <p>{fqa.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default IndexPage
