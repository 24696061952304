import classnames from 'classnames'
import * as React from 'react'
import { ThemeContext } from '../../contexts/ThemeContext'
import PropTypes from '../../utils/PropTypes'
import Logo1 from '../../images/logos/logo1.svg'
import logo2 from '../../images/logos/logo2.svg'
import logo3 from '../../images/logos/logo3.svg'
import Logo4 from '../../images/logos/logo4.svg'
import logo5 from '../../images/logos/logo5.svg'
import logo6 from '../../images/logos/logo6.svg'
import logo7 from '../../images/logos/logo7.svg'
import logo8 from '../../images/logos/logo8.svg'
import logo9 from '../../images/logos/logo9.svg'
import logo1_dark from '../../images/logos/logo1-dark.png'
import logo2_dark from '../../images/logos/logo2-dark.png'
import logo3_dark from '../../images/logos/logo3-dark.png'
import logo4_dark from '../../images/logos/logo4-dark.png'
import logo5_dark from '../../images/logos/logo5-dark.png'
import logo6_dark from '../../images/logos/logo6-dark.png'
import logo7_dark from '../../images/logos/logo7-dark.png'
import logo8_dark from '../../images/logos/logo8-dark.png'
import logo9_dark from '../../images/logos/logo9-dark.png'
const companies = [
  {
    logo: Logo1,
    logo_dark: logo1_dark,
    title: 'Company Logo',
  },
  {
    logo: logo2,
    logo_dark: logo2_dark,
    title: 'Company Logo',
  },
  {
    logo: logo3,
    logo_dark: logo3_dark,
    title: 'Company Logo',
  },
  {
    logo: Logo4,
    logo_dark: logo4_dark,
    title: 'Company Logo',
  },
  {
    logo: logo5,
    logo_dark: logo5_dark,
    title: 'Company Logo',
  },
  {
    logo: logo6,
    logo_dark: logo6_dark,
    title: 'Company Logo',
  },
  {
    logo: logo7,
    logo_dark: logo7_dark,
    title: 'Company Logo',
  },
  {
    logo: logo8,
    logo_dark: logo8_dark,
    title: 'Company Logo',
  },
  {
    logo: logo9,
    logo_dark: logo9_dark,
    title: 'Company Logo',
  },
]
const TrustedCompany = ({ className, container }) => {
  const { theme /* , setTheme  */ } = React.useContext(ThemeContext)
  return (
    <div
      className={classnames('', className, {
        'container-md': container === 'md',
        'container-sm': container === 'sm',
      })}
    >
      <div className="flex flex-wrap justify-around items-center max-w-6xl">
        {companies.map((item,index) => (
          <div key={index} className="mx:4 md:mx-6 my-3">
            {theme === 'dark' ? (
              <img
                className="w-40 h-10 md:h-auto"
                src={item.logo_dark}
                height="96"
                width="180"
                alt="Company Logo"
                srcset=""
              />
            ) : (
              /* <Logo4 /> */ /* className='w-40 h-24' */
              <img
                className="w-44 h-20"
                src={item.logo}
                height="96"
                width="180"
                alt="Company logo"
                srcset=""
                style={{ aspectRatio: 'auto' }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

TrustedCompany.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.string,
}

TrustedCompany.defaultProps = {
  children: null,
  className: '',
  container: 'md',
}

export default TrustedCompany
